import * as React from "react"

import Layout from "./Layout"
import {
  BreadLink,
  BreadTypography,
  Capitalize,
  PageHeader,
  ProductCard,
} from "./partials"
import {
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
  withStyles,
} from "@material-ui/core"
import Image from "./Image"
import { CategoryStyles } from "../styles/CategoryStyles"
import { navigate } from "gatsby"
import FeaturedBanner from "./FeaturedBanner"
import { Model } from "../types/Types"
import { PageContext } from "gatsby/internal"

const ProductListPage = ({ pageContext }: PageContext) => {
  const { product, modelList } = pageContext

  console.log(modelList);

  const redirect = target => navigate(`/products/${target.toLowerCase()}`)
  const breadComponent = modelList && (
    <Breadcrumbs aria-label="breadcrumb">
      <BreadLink underline="hover" color="inherit" href="/categories">
        Products
      </BreadLink>
      <BreadTypography> {product}</BreadTypography>
    </Breadcrumbs>
  )

  return (
    modelList && (
      <Layout
        HeaderComponent={
          <PageHeader id={"productsHeader"} header={Capitalize(product)} />
        }
        BreadComponent={breadComponent}
        hasFeatured={true}
      >
        <Container style={{ padding: 0, width: "100%" }}>
          <Grid
            container
            style={{ paddingTop: "10px", width: "auto" }}
            spacing={1}
            justify={"center"}
            xs={12}
            md={12}
          >
            {modelList.map(model => (
              <Grid item container justify="center" style={{ width: "auto" }}>
                <ProductCard model={model as Model} />
              </Grid>
            ))}
          </Grid>
        </Container>
      </Layout>
    )
  )
}

export default ProductListPage
